import { Injectable } from "@angular/core";
// import { EmailComposer, EmailComposerOptions, } from '@ionic-native/email-composer';
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { concat, from, Observable, Observer, of, Subject } from "rxjs";

import {
  Asset,
  AssetType,
  Category,
  getAssetBuildingId,
  makePriceSheet,
  Perimeter,
  PriceSheet,
  setAsset,
  SubCategory,
} from "../structs/assets";
import {
  BillingType,
  createInvestmentData,
  getInvestmentSliceData,
  getInvestmentSliceFullPrice,
  ImportableInvestmentStatus,
  Investment,
  InvestmentBudgetOrigin,
  InvestmentCategory,
  InvestmentExecution,
  InvestmentFurtherInformation,
  InvestmentPriority,
  InvestmentReason,
  InvestmentStatus,
  InvestmentType,
  makeInvestment,
  makeInvestmentBudgetOrigin,
  makeInvestmentCategory,
  makeInvestmentExecution,
  makeInvestmentFurtherInformation,
  makeInvestmentReason,
  makeInvestmentStatus,
  makeInvestmentType,
  TaxonomyCategory,
  TaxonomyObjective,
  InvestmentLink,
  IBudgetScope,
  InvestmentSlice,
  InvestmentStatusTransition,
} from "../structs/investments";
import { Change, ChangeAction, makeChange } from "@structs/synchronization";
import { AuditNotation } from "../structs/audit";
import { getLocalId } from "../structs/utils";

import { BackendService } from "./backend.service";
import { ErrorsService } from "./errors.service";
// import { ImportStatusService } from './import-status.service';
import { OfflineService } from "./offline.service";
import { SynchronizationService } from "./synchronization.service";
import { ScopeService } from "./scope.service";
import { INVESTMENT_OBJECT, SuccessToastService } from "./success-toast.service";
import { SynthesisService } from "./synthesis.service";
import { CurrencyPipe } from "../pipes/currency/currency.pipe";
import { combineLatest, forkJoin } from "rxjs/";
import { catchError, concatMap, filter, map, switchMap } from "rxjs/operators";
import { ImportStatusService } from "./import-status.service";
import { BrowserService } from "./browser.service";
import { I18nService } from "./i18n.service";
import { MembersGroup, User } from "@structs";

const DELETE_GLOBAL_INVESTMENT_ACTION = 0;
const ADD_GLOBAL_INVESTMENT_ACTION = 1;
const PATCH_GLOBAL_INVESTMENT_ACTION = 2;

export enum EolType {
  AUDIT = "AUDIT",
  THEORETICAL = "THEORETICAL",
}

@Injectable()
export class InvestmentsService {
  /**
   * Tracking newly created Investment. The value is the localID of the
   * new Investment
   */
  private readonly newInvestmentCreated: Subject<Investment> = new Subject();
  public newInvestmentCreated$ = this.newInvestmentCreated.asObservable();
  private readonly investmentChanged: Subject<Investment> = new Subject();
  public investmentChanged$ = this.investmentChanged.asObservable();
  private readonly investmentDeleted: Subject<Investment> = new Subject();
  public investmentDeleted$ = this.investmentDeleted.asObservable();

  constructor(
    private backend: BackendService,
    private offlineApi: OfflineService,
    private errors: ErrorsService,
    private syncApi: SynchronizationService,
    private scope: ScopeService,
    private translate: TranslateService,
    private successToast: SuccessToastService,
    private synthesisApi: SynthesisService,
    // private emailComposer: EmailComposer,
    private currencyPipe: CurrencyPipe,
    private importStatusService: ImportStatusService,
    private browserService: BrowserService,
    private i18n: I18nService
  ) {}

  getInvestmentTypes(assetType: AssetType = null): Observable<Array<InvestmentType>> {
    return new Observable(observer => {
      this.offlineApi.getConfig("investmentTypes").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let filteredInvestmentTypes: Array<InvestmentType> = [];
          let allInvestmentTypes: Array<InvestmentType> = [];
          for (let i = 0, l = data.length; i < l; i++) {
            let investmentType: InvestmentType = makeInvestmentType(data[i]);
            allInvestmentTypes.push(investmentType);
            if (assetType !== null) {
              // filter only investments for the asset type
              if (investmentType.onlyForAssetTypes.indexOf(assetType.id) >= 0) {
                filteredInvestmentTypes.push(investmentType);
              }
            }
          }
          // If no filter by asset types : show all
          if (filteredInvestmentTypes.length === 0) {
            observer.next(allInvestmentTypes);
          } else {
            observer.next(filteredInvestmentTypes);
          }
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  getInvestmentCategories(): Observable<InvestmentCategory[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("investmentCategories").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let investmentCategories: InvestmentCategory[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            let investmentCategory: InvestmentCategory = makeInvestmentCategory(data[i]);
            investmentCategories.push(investmentCategory);
          }
          observer.next(investmentCategories);
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  getInvestmentCategoryParent(childId: Number): Observable<InvestmentCategory> {
    return new Observable(observer => {
      this.getInvestmentCategories().subscribe(investmentCategories => {
        let parent = investmentCategories.find(parentCategory => {
          return !!parentCategory.children.find(childCategory => childCategory.id === childId);
        });
        observer.next(parent);
        observer.complete();
      });
    });
  }

  getInvestmentExecutions(): Observable<Array<InvestmentExecution>> {
    return new Observable(observer => {
      this.offlineApi.getConfig("investmentExecutions").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let investmentExecutions: Array<InvestmentExecution> = [];
          for (let i = 0, l = data.length; i < l; i++) {
            let investmentExecution: InvestmentExecution = makeInvestmentExecution(data[i]);
            investmentExecutions.push(investmentExecution);
          }
          observer.next(investmentExecutions);
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  getDefaultInvestmentType(): Observable<InvestmentType> {
    return new Observable(observer => {
      this.getInvestmentTypes().subscribe(
        items => {
          let defaultValue: InvestmentType = null;
          for (let i = 0; i < items.length; i++) {
            if (items[i].isDefault) {
              defaultValue = items[i];
              break;
            }
          }
          observer.next(defaultValue);
          observer.complete();
        },
        err => {
          observer.next(null);
          observer.complete();
        }
      );
    });
  }

  getDefaultInvestmentReasons(investmentType: InvestmentType): Observable<Array<InvestmentReason>> {
    return new Observable(observer => {
      this.getInvestmentReasons().subscribe(
        items => {
          let defaultReasons: Array<InvestmentReason> = [];
          for (let i = 0; i < items.length; i++) {
            let reason: InvestmentReason = items[i];
            if (items[i].isDefault) {
              let noTypeLimitation = reason.onlyForInvestmentTypes.length === 0;
              let allowedForThisType = reason.onlyForInvestmentTypes.indexOf(investmentType.id) >= 0;
              if (noTypeLimitation || allowedForThisType) {
                defaultReasons.push(items[i]);
              }
            }
          }
          observer.next(defaultReasons);
          observer.complete();
        },
        err => {
          observer.next(null);
          observer.complete();
        }
      );
    });
  }

  getDefaultInvestmentStatus(): Observable<InvestmentStatus> {
    return new Observable(observer => {
      this.getInvestmentStatus().subscribe(
        items => {
          let defaultValue: InvestmentStatus = null;
          for (let i = 0; i < items.length; i++) {
            if (items[i].isDefault) {
              defaultValue = items[i];
              break;
            }
          }
          observer.next(defaultValue);
          observer.complete();
        },
        err => {
          console.error(err);
          observer.next(null);
          observer.complete();
        }
      );
    });
  }

  getInvestmentReasons(): Observable<Array<InvestmentReason>> {
    return new Observable(observer => {
      this.offlineApi.getConfig("investmentReasons").subscribe(
        (jsonData: any) => {
          let reasons: Array<InvestmentReason> = [];
          let data = jsonData ? jsonData : [];
          for (let i = 0; i < data.length; i++) {
            reasons.push(makeInvestmentReason(data[i]));
          }
          observer.next(reasons);
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  getBudgetScopes(): Observable<Array<IBudgetScope>> {
    return new Observable(observer => {
      this.offlineApi.getConfig("budgetScopes").subscribe(
        (jsonData: any) => {
          let scopes: Array<IBudgetScope> = [];
          let data = jsonData ? jsonData : [];
          for (let i = 0; i < data.length; i++) {
            scopes.push(data[i]);
          }
          observer.next(scopes);
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  public getInvestmentLinks(): Observable<InvestmentLink[]> {
    return this.offlineApi
      .getConfig("investmentLinks")
      .pipe(map((links: InvestmentLink[]) => links?.sort((a, b) => a.ordering - b.ordering) || []));
  }

  getBillingTypes(): Observable<Array<BillingType>> {
    return new Observable(observer => {
      this.offlineApi.getConfig("billingTypes").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          observer.next(data);
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  getInvestmentStatus(): Observable<InvestmentStatus[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("investmentStatus").subscribe(
        (jsonData: any) => {
          let listOfStatus: Array<InvestmentStatus> = [];
          let data = jsonData ? jsonData : [];
          for (let i = 0; i < data.length; i++) {
            let status: InvestmentStatus = makeInvestmentStatus(data[i]);
            listOfStatus.push(status);
          }
          observer.next(listOfStatus);
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  getInvestmentPriorities(): Observable<Array<InvestmentPriority>> {
    return new Observable(observer => {
      this.offlineApi.getConfig("investmentPriorities").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          observer.next(data);
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  getTaxonomyObjectives(): Observable<TaxonomyObjective[]> {
    return this.offlineApi.getConfig("taxonomyObjectives");
  }

  getTaxonomyCategories(): Observable<TaxonomyCategory[]> {
    return this.offlineApi.getConfig("taxonomyCategories");
  }
  getInvestmentFurtherInformation(): Observable<Array<InvestmentFurtherInformation>> {
    return new Observable(observer => {
      this.offlineApi.getConfig("investmentFurtherInformation").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData.map(elt => makeInvestmentFurtherInformation(elt)) : [];
          observer.next(data);
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  // in case of no asset, this function try to patch global investment
  public prepareInvestmentForLocalStore(asset: Asset | null, investment: Investment, observer?: Observer<Investment>) {
    if (asset) {
      for (let i = 0; i < asset.investments.length; i++) {
        if (InvestmentsService.areTheSameInvestment(investment, asset.investments[i])) {
          asset.investments[i] = investment;
          break;
        }
      }
      this._storeInvestments(asset, observer, investment);
    } else {
      // orphan investment
      this._storeGlobalInvestments(observer, investment, PATCH_GLOBAL_INVESTMENT_ACTION);
    }
    this.investmentChanged.next(investment);
  }

  _storeInvestments(asset: Asset, observer, investment: Investment) {
    this.offlineApi.storeAsset(asset).subscribe(
      () => {
        this.syncApi.signalOfflineChanges().subscribe(
          () => {
            observer?.next(investment);
            observer?.complete();
          },
          err => {
            observer?.error(err);
            observer?.complete();
          }
        );
      },
      err => {
        observer?.error(err);
        observer?.complete();
      }
    );
  }

  protected _storeGlobalInvestments(observer, investment: Investment, action: number) {
    let observable = null;
    if (action === ADD_GLOBAL_INVESTMENT_ACTION) {
      observable = this.offlineApi.addGlobalInvestment(investment);
    } else if (action === PATCH_GLOBAL_INVESTMENT_ACTION) {
      observable = this.offlineApi.patchGlobalInvestment(investment);
    } else if (action === DELETE_GLOBAL_INVESTMENT_ACTION) {
      observable = this.offlineApi.deleteGlobalInvestment(investment);
    }
    if (observable !== null) {
      observable.subscribe(
        () => {
          this.syncApi.signalOfflineChanges().subscribe(
            () => {
              observer.next(investment);
              observer.complete();
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    } else {
      const message = "_storeGlobalInvestments. Action " + action + " is not implemented";
      console.error(message);
      observer.error(message);
      observer.complete();
    }
  }

  public addNewInvestment(
    investment: Investment,
    asset: Asset = null,
    buildingId: number = 0,
    perimeterLocalId: string = ""
  ): Observable<Investment> {
    return new Observable(observer => {
      investment.localId = getLocalId();
      let data: any = createInvestmentData(investment);
      let url = "/investments/api/investments/";
      if (asset) {
        data.asset = asset.id;
        investment.buildingId = getAssetBuildingId(asset);
      } else {
        data.building = buildingId;
        investment.buildingId = buildingId;
      }

      this.syncApi
        .addChange(
          makeChange(
            ChangeAction.addInvestmentAction,
            url,
            "post",
            data,
            asset,
            investment.localId,
            null,
            perimeterLocalId
          )
        )
        .subscribe(
          () => {
            // modify the asset
            investment.notes = {};
            if (asset) {
              // the investment is attached to an asset
              investment.assetId = asset.offline ? asset.offlineId : asset.id;
              investment.assetOffline = asset.offline;
              asset.investments.push(investment);
              this._storeInvestments(asset, observer, investment);
            } else {
              // orphan investments
              this._storeGlobalInvestments(observer, investment, ADD_GLOBAL_INVESTMENT_ACTION);
            }
            this.newInvestmentCreated.next(investment);
            this.successToast.showObjectCreated(INVESTMENT_OBJECT);
          },
          err => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getInvestments(asset: Asset, showChildrenInvestments: boolean = false): Observable<Array<Investment>> {
    return new Observable(observer => {
      this.offlineApi.loadAsset(asset.id, asset.offlineId).subscribe(
        asset => {
          let allInvestments = asset.investments;
          if (showChildrenInvestments) {
            for (let child of asset.children) {
              allInvestments = allInvestments.concat(child.investments);
            }
          }
          observer.next(allInvestments);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  static areTheSameInvestment(investment1, investment2): boolean {
    if (investment1.id > 0) {
      return investment1.id === investment2.id;
    } else {
      return investment1.localId === investment2.localId;
    }
  }

  patchInvestment(asset: Asset, investment: Investment, data: any, perimeterLocalId = ""): Observable<Investment> {
    return new Observable(observer => {
      console.log("****patchInvestment", investment);
      // Following #4471, for unknown reason, we couldn't patch the investment patch
      // URL after the investment creation is synced. This will bring more information
      // to the backend to handle by adding the investmentLocalId to the payload
      const annotatedData = {
        ...data,
        ...{
          __CONTEXT__: {
            investmentLocalId: investment.localId,
            investmentId: investment.id,
          },
        },
      };
      // Try to get the id from local map
      this.offlineApi.getFromInvestmentIdsMap(investment.localId).subscribe((investmentId: number) => {
        let investId = investment.id === 0 ? investmentId : investment.id;
        let url = "/investments/api/investments/" + investId + "/";
        this.syncApi
          .addChange(
            makeChange(
              ChangeAction.saveInvestmentAction,
              url,
              "patch",
              annotatedData,
              asset,
              investment.localId,
              null,
              perimeterLocalId
            )
          )
          .subscribe(
            () => {
              this.prepareInvestmentForLocalStore(asset, investment, observer);
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
      });
    });
  }

  getInvestment(investmentId: number): Observable<Investment> {
    return new Observable(observer => {
      this.backend.get("/investments/api/investments/" + investmentId + "/").subscribe(
        jsonData => {
          observer.next(makeInvestment(jsonData));
          observer.complete();
        },
        err => {
          // rej(err)
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  public getInvestmentById(investId: string, multiPerimeter: Perimeter): Observable<Investment> {
    return this.getPerimeterInvestments(multiPerimeter, true).pipe(
      map(investments => {
        if (investId === "0") {
          return null;
        }
        const investment = investments.find(investment => {
          if (investId.toString().includes(".")) {
            // Offline invest, we have a local id
            return investment.localId === investId;
          } else {
            return investment.id.toString() === investId;
          }
        });
        return investment;
      })
    );
  }

  deleteInvestment(asset: Asset, investment: Investment): Observable<Investment> {
    return new Observable(observer => {
      this.offlineApi.getFromInvestmentIdsMap(investment.localId).subscribe((investmentId: number) => {
        let investId = investment.id === 0 ? investmentId : investment.id;
        let url = "/investments/api/investments/" + investId + "/";

        // modify the asset
        if (asset) {
          let index = -1;
          for (let i = 0; i < asset.investments.length; i++) {
            if (InvestmentsService.areTheSameInvestment(investment, asset.investments[i])) {
              index = i;
              break;
            }
          }
          if (index >= 0) {
            asset.investments.splice(index, 1);
          }
        }

        this.syncApi
          .addChange(makeChange(ChangeAction.deleteInvestmentAction, url, "delete", {}, asset, investment.localId))
          .subscribe(
            () => {
              if (asset) {
                this._storeInvestments(asset, observer, null);
              } else {
                console.log("*** DELETE_GLOBAL_INVESTMENT_ACTION");
                this._storeGlobalInvestments(observer, investment, DELETE_GLOBAL_INVESTMENT_ACTION);
              }
              this.investmentDeleted.next(investment);
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
      });
    });
  }

  /**
   * searchInvestments :
   * @param criteria
   * @returns Observable on array investments
   */
  searchInvestments(criteria: any): Observable<Investment[]> {
    return new Observable(observer => {
      this.scope.getSelectedPerimeter().subscribe(
        perimeter => {
          let url: string = "/investments/api/search-investments/" + perimeter.id + "/";
          this.backend.post(url, criteria).subscribe(
            (jsonData: Array<any>) => {
              let investments: Investment[] = [];
              for (let i = 0, l = jsonData.length; i < l; i++) {
                if (jsonData[i].asset) {
                  jsonData[i].asset = jsonData[i].asset.id;
                }
                investments.push(makeInvestment(jsonData[i]));
              }
              observer.next(investments);
              observer.complete();
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * searchInvestments :
   * @param investments: list of investments to process
   * @param action: id of the action. it can be : delete, year, priority, status
   * @param value: optional value
   * @param value2: optional additional value : reason for year action
   * @returns Observable
   */
  processInvestments(
    investments: Investment[],
    action: string,
    value: number = null,
    value2: number = null
  ): Observable<boolean> {
    return new Observable(observer => {
      this.scope.getSelectedPerimeter().subscribe(
        perimeter => {
          let url: string = "/investments/api/process-investments/" + perimeter.id + "/";
          let investmentIds: Array<number> = [];
          for (let i = 0; i < investments.length; i++) {
            investmentIds.push(investments[i].id);
          }
          let data: any = {
            investments: investmentIds,
            action: action,
          };
          if (value !== null) {
            data.value = value;
          }
          if (value2 !== null) {
            data.value2 = value2;
          }
          this.backend.post(url, data).subscribe(
            (jsonData: Array<any>) => {
              observer.next(true);
              observer.complete();
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * create or update the note of an asset during an audit
   * @param asset
   * @param investment : investment causing this change of notation
   * @param notation : list of (questionItem, note)
   * @returns Observable<boolean> : success of the operation
   */
  setAuditNoteAfterInvestment(asset: Asset, investment: Investment, notation: AuditNotation): Observable<boolean> {
    let url: string = "";
    if (investment.id) {
      url = "/investments/api/notation/" + asset.id + "/" + investment.id + "/";
    } else {
      url = "/investments/api/notation/" + asset.id + "/l/" + investment.localId + "/";
    }
    return new Observable(observer => {
      // If the asset if offline (not yet on server), keep things local
      let change: Change = makeChange(ChangeAction.setAuditNoteAfterInvestmentAction, url, "post", notation, asset);
      this.syncApi.addChange(change).subscribe(
        () => {
          // update notes
          for (let i = 0; i < asset.investments.length; i++) {
            let assetInvestment = asset.investments[i];
            if (InvestmentsService.areTheSameInvestment(investment, assetInvestment)) {
              assetInvestment.notes = investment.notes;
              break;
            }
          }
          this.offlineApi.storeAsset(asset).subscribe(
            () => {
              // Push changes
              this.syncApi.signalOfflineChanges().subscribe(
                () => {},
                err => {},
                () => {
                  observer.next(true);
                  observer.complete();
                }
              );
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  addDefaultInvestment(asset): Observable<Asset> {
    return Observable.create(observer => {
      this.getDefaultInvestmentType().subscribe(
        (defaultType: InvestmentType) => {
          let investmentYear: number = asset.installationYear + asset.assetType.expected_duration;
          let currentYear: number = moment().year();
          // CAP-404 -> Création d'un investissement par défaut sauf si durée de vie > 100 ans
          const maxYears: number = 100;
          let yearsUntilInvestment: number = investmentYear - currentYear;
          if (defaultType !== null && yearsUntilInvestment <= maxYears) {
            // TODO
            observer.error("TODO addDefaultInvestment");
            observer.next(asset);
            observer.complete();
          } else {
            observer.next(asset);
            observer.complete();
          }
        },
        err => {
          this.errors.signalError(err);
          observer.next(asset);
          observer.complete();
        }
      );
    });
  }

  protected getAssetInvestments(perimeter: Perimeter, showChildrenInvestments = false): Observable<Investment[]> {
    return new Observable(observer => {
      this.scope.getSynthesisYear().subscribe(
        year => {
          this.synthesisApi.browseSynthesisAssets().subscribe(
            (asset: Asset) => {
              // TODO : only for assets of the current perimeter
              asset.investments.forEach(inv => {
                inv.subCategory = asset.subCategory;
                inv.category = asset.category;
              });
              let allInvestments = asset.investments;
              if (showChildrenInvestments) {
                for (let child of asset.children) {
                  const childAsset = setAsset(child); // get the compatible version of the asset
                  allInvestments = allInvestments.concat(childAsset.investments);
                }
              }
              observer.next(allInvestments);
            },
            () => {},
            () => {
              observer.complete();
            }
          );
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getGlobalInvestments(perimeter: Perimeter): Observable<Investment[]> {
    return new Observable(observer => {
      let buildingIds = [];
      if (perimeter.building_id) {
        buildingIds.push(perimeter.building_id);
      }
      if (perimeter.sub_perimeters) {
        buildingIds = buildingIds.concat(
          perimeter.sub_perimeters.map(elt => {
            return elt.building_id > 0 ? elt.building_id : elt.localId;
          })
        );
      }
      let buildingIdsObservable = from(buildingIds);
      buildingIdsObservable
        .pipe(
          concatMap((buildingId: number) => {
            return this.offlineApi.getGlobalInvestments(buildingId);
          })
        )
        .subscribe(
          investments => {
            observer.next(investments);
          },
          err => {
            console.error(err);
          },
          () => {
            observer.complete();
          }
        );
    });
  }

  public getPerimeterInvestments(perimeter: Perimeter, showChildrenInvestments = false): Observable<Investment[]> {
    return new Observable(observer => {
      let allInvestments = [];
      // get all asset investments and then all global investments from offline service
      concat(
        this.getAssetInvestments(perimeter, showChildrenInvestments),
        this.getGlobalInvestments(perimeter)
      ).subscribe(
        investments => {
          allInvestments = allInvestments.concat(investments);
        },
        err => {
          observer.error(err);
        },
        () => {
          observer.next(allInvestments);
          observer.complete();
        }
      );
    });
  }

  public sendInvestmentByEmail(investment: Investment, asset: Asset) {
    const inBrowser = this.browserService.inBrowser();

    forkJoin(
      this.translate.get("Request regarding the investment: {{label}}", {
        label: investment.label,
      }),
      this.translate.get("About this investment: {{label}}", {
        label: investment.label,
      }),
      this.translate.get("Type: {{type}}", {
        type: investment.investmentType.name,
      }),
      this.translate.get("Priority: {{priority}}", {
        priority: investment.priority ? investment.priority.name : "",
      }),
      this.translate.get("Status: {{status}}", {
        status: investment.status.name,
      }),
      this.translate.get("Budget"),
      this.getLinkInformation(investment, asset)
    ).subscribe(([subject, about, type, priority, status, budget, linkInformation]) => {
      let body = "";
      if (inBrowser) {
        body = `
          ${about}

          ${linkInformation}

          ${type}:

            ${investment.priority ? `- ${priority}` : ""}
            - ${status}

          ${budget}:
            ${investment.slices
              .map(
                slice => `
            - ${slice.year}: ${this.currencyPipe.transform(getInvestmentSliceFullPrice(slice))}`
              )
              .join("")}
        `;
      } else {
        body = `
          <h2>${about}</h2>
          <p>${linkInformation}</p>
          <ul>
            <li>${type}</li>
            ${investment.priority ? `<li>${priority}</li>` : ""}
            <li>${status}</li>
          </ul>
          <h2>${budget}</h2>
          <ul>
            ${investment.slices
              .map(
                slice => `<li>${slice.year}: ${this.currencyPipe.transform(getInvestmentSliceFullPrice(slice))}</li>`
              )
              .join("")}
          </ul>
        `;
      }

      // const email: EmailComposerOptions = {
      //   subject,
      //   body,
      //   isHtml: true,
      // };
      // this.emailComposer
      //   .open(email)
      //   .catch((err) => this.errors.signalError(err));
    });
  }

  public attachInvestmentToAsset(investment: Investment, asset: Asset): Observable<Investment> {
    return new Observable(observer => {
      if (investment.assetId !== null) {
        this.translate.get("The investment is already attached to an asset").subscribe(text => {
          observer.error(text);
          observer.complete();
        });
      } else {
        const url = "/investments/api/attach-investment/";
        let data = {
          asset_id: asset.id,
          investment_local_id: investment.localId,
        };
        investment.assetId = asset.offline ? asset.offlineId : asset.id;
        investment.assetOffline = asset.offline;
        investment.building = null;
        investment.monoPerimeterLocalId = "";
        investment.importStatuses = <ImportableInvestmentStatus[]>(
          this.importStatusService.removeImportStatus(ImportableInvestmentStatus.ASSET_LINK, investment.importStatuses)
        );

        // attach it to the asset
        asset.investments.push(investment);
        this.syncApi
          .addChange(makeChange(ChangeAction.attachInvestmentAction, url, "post", data, asset, investment.localId))
          .pipe(
            concatMap(() =>
              this.patchInvestment(asset, investment, {
                import_statuses: investment.importStatuses,
              })
            )
          )
          .subscribe(
            () => {
              // This is not a global investment anymore
              this.offlineApi.deleteGlobalInvestment(investment).subscribe(
                () => {},
                err => {},
                () => {
                  this.offlineApi.storeAsset(asset).subscribe(
                    () => {
                      this.syncApi.signalOfflineChanges().subscribe(
                        () => {
                          observer.next(investment);
                          observer.complete();
                        },
                        err => {
                          observer.error(err);
                          observer.complete();
                        }
                      );
                    },
                    err => {
                      observer.error(err);
                      observer.complete();
                    }
                  );
                }
              );
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
      }
    });
  }

  public detachInvestmentFromAsset(investment: Investment, asset: Asset): Observable<Investment> {
    return new Observable(observer => {
      const url = "/investments/api/attach-investment/";
      let data = {
        asset_id: asset.id,
        asset_local_id: asset.offlineId,
        investment_local_id: investment.localId,
        detach: true,
      };
      investment.assetId = null;
      investment.assetOffline = false;
      investment.building = asset.building;
      investment.monoPerimeterLocalId = asset.building.monosite_perimeter.localId;
      investment.category = asset.category;
      investment.subCategory = asset.subCategory;
      investment.priceSheet = null;

      let found = -1;
      for (let index = 0; index < asset.investments.length; index++) {
        let current = asset.investments[index];
        if (
          (current.id && current.id === investment.id) ||
          (current.localId && current.localId === investment.localId)
        ) {
          found = index;
          break;
        }
      }
      // detach the investment
      if (found >= 0) {
        asset.investments.splice(found, 1);
      }

      this.syncApi
        .addChange(makeChange(ChangeAction.attachInvestmentAction, url, "post", data, asset, investment.localId))
        .subscribe(
          () => {
            // This is now a global investment
            this.offlineApi.addGlobalInvestment(investment).subscribe(
              () => {},
              err => {},
              () => {
                this.offlineApi.storeAsset(asset).subscribe(
                  () => {
                    this.syncApi.signalOfflineChanges().subscribe(
                      () => {
                        observer.next(investment);
                        observer.complete();
                      },
                      err => {
                        observer.error(err);
                        observer.complete();
                      }
                    );
                  },
                  err => {
                    observer.error(err);
                    observer.complete();
                  }
                );
              }
            );
          },
          err => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  /**
   * Return information about the asset (asset label (MultiPerimeter/MonoPerimeter/Cactegory/Sub-category/Asset type) if an asset is linked to the investment
   * Else return information about the global investment (MultiPerimeter/MonoPerimeter/Category/Sub-category)
   *
   * @param inBrowser
   * @param asset
   */
  private getLinkInformation(investment: Investment, asset: Asset): Observable<string> {
    return combineLatest(this.scope.getSelectedPerimeter(), this.translate.get("Associated asset")).pipe(
      map(([perimeter, associatedAsset]) => {
        let info = "";
        if (asset) {
          const monoPerim = perimeter.sub_perimeters.find(subPerim => subPerim.building.id === asset.building.id);
          info = `${associatedAsset}: ${asset.label} (${perimeter.name}/${monoPerim.name}/${asset.category.name}/${asset.subCategory.name}/${asset.assetType.name})`;
        } else {
          const monoPerim = perimeter.sub_perimeters.find(subPerim => subPerim.building.id === investment.buildingId);
          const parts: string[] = [perimeter.name, monoPerim.name];
          if (investment.category) {
            parts.push(investment.category.name);
          }
          if (investment.subCategory) {
            parts.push(investment.subCategory.name);
          }
          info = `${investment.label}: ${parts.join("/")}`;
        }
        return info;
      })
    );
  }

  getSlicesData(investment: Investment) {
    const updateData: any = {};
    // First the slices
    updateData.slices = investment.slices.map(getInvestmentSliceData);
    investment.slices.forEach(slice => {
      let foundSlice = updateData.slices.find(backendSlice => backendSlice.id === slice.id);
    });
    return updateData;
  }

  getBudgetOrigins(): Observable<InvestmentBudgetOrigin[]> {
    return this.offlineApi.getConfig("budgetOrigins").pipe(
      filter(jsonData => !!jsonData),
      map(jsonData => jsonData.map(elt => makeInvestmentBudgetOrigin(elt)) ?? []),
      catchError(async err => this.errors.signalError(err))
    );
  }

  public getPriceSheets(): Observable<Array<PriceSheet>> {
    return new Observable(observer => {
      this.offlineApi.getConfig("priceSheets").subscribe(
        (jsonData: any) => {
          let priceSheets: Array<PriceSheet> = [];
          let data = jsonData ? jsonData : [];
          for (let i = 0; i < data.length; i++) {
            priceSheets.push(makePriceSheet(data[i]));
          }
          observer.next(priceSheets);
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
          observer.complete();
        }
      );
    });
  }

  public hasWorkflowRound(isWorkflowEnabled: boolean, perimeter: Perimeter, year: number): Observable<boolean> {
    return new Observable(observer => {
      if (isWorkflowEnabled) {
        const url: string = "/workflow/api/has-running-round/" + perimeter.id + "/" + year + "/";
        this.backend.get(url).subscribe(
          (jsonData: any) => {
            observer.next(!!jsonData.running_round);
            observer.complete();
          },
          err => {
            observer.error(err);
            observer.complete();
          }
        );
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  public remainingFlashBudget(
    isWorkflowEnabled: boolean,
    perimeter: Perimeter,
    year: number,
    investmentId: number
  ): Observable<number> {
    return new Observable(observer => {
      if (isWorkflowEnabled) {
        const url: string = "/workflow/api/check-flash-investments-budget/" + perimeter.id + "/" + year + "/";
        this.backend.get(url, { ignore: investmentId }).subscribe(
          (jsonData: any) => {
            observer.next(jsonData.remaining_budget);
            observer.complete();
          },
          err => {
            observer.error(err);
            observer.complete();
          }
        );
      } else {
        observer.next(0);
        observer.complete();
      }
    });
  }

  public formatSlicePrice(slice: InvestmentSlice): string {
    let formattedPrice: string;

    slice.price = this.i18n.setMaxValue(slice.price, 12);

    if (slice.additionalPrice) {
      const fullPrice = this.i18n.setMaxValue(slice.price + slice.additionalPrice, 12);
      formattedPrice = `${this.currencyPipe.transform(fullPrice)} (${this.currencyPipe.transform(
        slice.price
      )} + ${this.currencyPipe.transform(slice.additionalPrice)})`;
    } else {
      formattedPrice = this.currencyPipe.transform(slice.price);
    }

    return formattedPrice;
  }

  /**
   * Returns the list of investment statuses that can be reached from the current status,
   * according to the user's groups and status transitions.
   * @param currentStatus
   * @param investmentStatuses
   * @param user
   * @param memberGroups
   */
  public filterInvestmentStatusByTransition(
    currentStatus: InvestmentStatus,
    investmentStatuses: InvestmentStatus[],
    user: User,
    memberGroups: MembersGroup[]
  ): InvestmentStatus[] {
    const currentUserGroups = memberGroups.filter(group => group.members.includes(user.id));

    const allowedTransitions: InvestmentStatusTransition[] = currentUserGroups.reduce(
      (acc, group) =>
        acc.concat(
          group.allowedInvestmentStatusTransitions.filter(transition => transition.fromStatus === currentStatus.id)
        ),
      []
    );

    return investmentStatuses.filter(status => {
      return allowedTransitions.some(transition => transition.toStatus === status.id);
    });
  }

  public filterInvestmentCategories(
    invCategories: InvestmentCategory[],
    category: Category | null,
    subCategory: SubCategory | null,
    assetType: AssetType | null
  ): InvestmentCategory[] {
    let filteredInvCategories = invCategories;
    if (category || subCategory || assetType) {
      // We filter the investment category by technical category
      if (category) {
        filteredInvCategories = filteredInvCategories.filter(invCategory => {
          return invCategory.categories.length === 0 || invCategory.categories.indexOf(category.id) >= 0;
        });
      }

      if (subCategory) {
        filteredInvCategories = filteredInvCategories.filter(invCategory => {
          return invCategory.subcategories.length === 0 || invCategory.subcategories.indexOf(subCategory.id) >= 0;
        });
      }

      if (assetType) {
        filteredInvCategories = filteredInvCategories.filter(invCategory => {
          return (
            !invCategory.assettypes ||
            invCategory.assettypes.length === 0 ||
            invCategory.assettypes.indexOf(assetType.id) >= 0
          );
        });
      }
      // We filter the investment category childrens by technical category
      filteredInvCategories.forEach(invCat => {
        if (invCat.children) {
          invCat.children = this.filterInvestmentCategories(invCat.children, category, subCategory, assetType);
        }
      });
    }
    return filteredInvCategories;
  }

  public getFilteredInvestmentCategories(investment: Investment): Observable<InvestmentCategory[]> {
    return new Observable(observer => {
      this.getInvestmentCategories().subscribe(
        categories => {
          const filteredCategories = this.filterInvestmentCategories(
            categories,
            investment.category,
            investment.subCategory,
            investment.assetType
          );
          observer.next(filteredCategories);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public createAutoPPA(items, price_sheets: { [asset: string]: number }[], eol_type: EolType): Observable<any> {
    const url: string = "/investments/api/auto-investment-plan/";
    const data = { preview: false, items, price_sheets, eol_type };
    return this.backend.post(url, data);
  }
}
