<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button *ngIf="selectMode" icon-only (click)="cancel()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      <ion-button *ngIf="showBackButton" (click)="backButtonClicked()">
        <i class="fa-solid fa-circle-chevron-left back-icon"></i>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons class="top-buttons" slot="end">
      <ion-button icon-only [color]="hasFiltersSelected ? 'danger' : 'default'" (click)="openAssetsFilters()">
        <span *ngIf="hasFiltersSelected && searchResults !== null" class="filter-result-counter">
          ({{ searchResults.length }})
        </span>
        <i class="fal fa-filter"></i>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      [(ngModel)]="searchText"
      (ionInput)="searchByKeywords()"
      placeholder="{{ 'Search' | translate }}"
      (ionCancel)="initSearchResults()"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-badge *ngIf="message" class="ion-padding">{{ message }}</ion-badge>
  <ion-list lines="full" *ngIf="allowAllPerimeters">
    <ion-item>
      <ion-label>{{ "View all perimeters" | translate }}</ion-label>
      <ion-toggle [(ngModel)]="showAllAssets" (ngModelChange)="toggleAllPerimeters()"></ion-toggle>
    </ion-item>
  </ion-list>
  <div class="no-asset-found" *ngIf="searchResults.length === 0 && selectMode">
    <div class="empty-value">{{ "No matching assets" | translate }}</div>
  </div>

  <ion-list lines="full" *ngFor="let asset of searchResults">
    <asset-item
      [asset]="asset"
      (assetClick)="onAssetClicked(asset)"
      (childrenClicked)="onAssetClicked($event)"></asset-item>
  </ion-list>

  <ion-fab vertical="center" horizontal="end" slot="fixed" *ngIf="showAddButton">
    <ion-fab-button ion-fab (click)="addAsset()" color="asset">
      <i class="fal fa-2x fa-plus"></i>
    </ion-fab-button>
  </ion-fab>
</ion-content>
