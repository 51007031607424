<ion-header>
  <ion-toolbar>
    <!-- <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons> -->
    <ion-buttons slot="start">
      <ion-button (click)="leave()"><i class="fa-solid fa-circle-chevron-left back-icon"></i></ion-button>
    </ion-buttons>
    <ion-title>
      <span class="ion-text-wrap">{{ title }}</span>
    </ion-title>
    <ion-buttons slot="end" *ngIf="!this.options.hideLinkToDetails">
      <ion-button (click)="openDetails()">
        <i class="fal fa-file-lines"></i>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="image-container" *ngIf="pictures.length > 0">
    <div id="case-left" class="arrow-icon" (click)="previousPicture()">
      <i class="fa-thin fa-4x fa-angle-left"></i>
    </div>
    <div id="case-right" class="arrow-icon" (click)="nextPicture()">
      <i class="fa-thin fa-4x fa-angle-right"></i>
    </div>
    <app-picture [picture]="pictures[selectedId]" [thumbnail]="false"></app-picture>
  </div>
  <div class="no-picture" *ngIf="pictures.length === 0">
    <camera (changed)="pictureAdded()"></camera>
  </div>
  <div class="thumbnails-container">
    <app-picture
      *ngFor="let picture of pictures; let index = index"
      [picture]="picture"
      [selected]="selectedId === index"
      (click)="selectPicture(index)"
      [isSitePic]="true"></app-picture>
  </div>
</ion-content>

<ion-footer>
  <app-footer-next-button
    *ngIf="showNextButton"
    (next)="validateChanges()"
    [label]="'validate'"
    [enabled]="true"
    color="asset"></app-footer-next-button>
  <ion-toolbar
    mode="ios"
    *ngIf="!showNextButton && pictures.length > 0"
    [color]="this.options.type === 'perimeter' ? 'mono-perimeter' : 'asset'">
    <div class="buttons-toolbar">
      <ion-buttons mode="ios" class="bar-buttons">
        <ion-button
          mode="ios"
          class="footer-button"
          fill="clear"
          [color]="this.options.type === 'perimeter' ? 'light' : 'dark'"
          expand="block"
          (click)="deletePicture()">
          <i class="fal fa-trash-alt trash-icon"></i>
        </ion-button>

        <ion-button
          mode="ios"
          class="footer-button"
          fill="clear"
          [color]="this.options.type === 'perimeter' ? 'light' : 'dark'"
          expand="block"
          (click)="addPicture()">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fak"
            data-icon="light-camera-circle-plus"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            class="uploaded-icon-preview svg-inline--fa fa-light-camera-circle-plus"
            style="width: 1.2em">
            <path
              fill="currentColor"
              d="M144 288c0-61.9 50.1-112 112-112c49.8 0 91.9 32.4 106.5 77.3c-10.8 12.6-19.9 26.7-26.9 42c.2-2.4 .3-4.8 .3-7.3c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80c28.8 0 54-15.2 68.1-38c-2.7 12.2-4.1 25-4.1 38c0 3.9 .1 7.8 .4 11.7C302.2 392.5 280 400 256 400c-61.9 0-112-50.1-112-112zM362.9 64.8L373.3 96c24.9 0 49.8 0 74.7 0c35.3 0 64 28.7 64 64c0 10.9 0 21.8 0 32.7c-5.3-.5-10.6-.7-16-.7s-10.7 .2-16 .7c0-10.9 0-21.8 0-32.7c0-17.7-14.3-32-32-32c-32.6 0-65.1 0-97.7 0c-5.9-17.7-11.8-35.4-17.7-53.1c-2.2-6.5-8.3-10.9-15.2-10.9c-40.9 0-81.9 0-122.8 0c-6.9 0-13 4.4-15.2 10.9c-5.9 17.7-11.8 35.4-17.7 53.1c-32.6 0-65.1 0-97.7 0c-17.7 0-32 14.3-32 32c0 85.4 0 170.7 0 256c0 17.7 14.3 32 32 32c91.8 0 183.5 0 275.2 0c5.9 11.4 12.9 22.2 21.1 32c-98.8 0-197.5 0-296.3 0c-35.4 0-64-28.7-64-64c0-85.4 0-170.7 0-256c0-35.3 28.7-64 64-64c24.9 0 49.8 0 74.7 0c3.5-10.4 6.9-20.8 10.4-31.2C155.6 45.2 173.9 32 194.6 32c40.9 0 81.9 0 122.8 0c20.7 0 39 13.2 45.5 32.8zM496 288c8.8 0 16 7.2 16 16c0 16 0 32 0 48c16 0 32 0 48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16c-16 0-32 0-48 0c0 16 0 32 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-16 0-32 0-48c-16 0-32 0-48 0c-8.8 0-16-7.2-16-16s7.2-16 16-16c16 0 32 0 48 0c0-16 0-32 0-48c0-8.8 7.2-16 16-16zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zM496 480c61.9 0 112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112s50.1 112 112 112z"
              class=""></path>
          </svg>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
  <input
    *ngIf="inBrowser"
    #browserFileUpload
    type="file"
    (click)="browserFileUpload.value = null"
    (change)="addBrowserPicture($event)"
    style="display: none"
    accept="image/*"
    multiple />
</ion-footer>
